<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="8">
            <div class="filter_row">
              <div class="filter_label d-md-down-none">공장 구분</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="factory" placeholder="공장을 선택하십시오" filterable clearable>
                <el-option-group
                  v-for="group in regions"
                  :key="`regions-${group.id}`"
                  :label="group.name">
                  <el-option
                    v-for="option in group.options"
                    :key="`factory-${option.id}`"
                    :label="option.name"
                    :value="option.sid">
                  </el-option>
                </el-option-group>
              </el-select>
              <div class="d-md-down-none">
                <CButton class="ml-4 my-1" color="primary" @click="onCreate" :disabled="factory === null">QR코드 출력</CButton>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'DeliveryQRCode',

  data() {
    return {
      factory_list: [],
      factory: null,
      qrcode_image: null
    }
  },
  computed: {
    regions() {
      let rtn = [];
      for (const factory of this.factory_list) {
        let rtn_region = rtn.find(el => el.name === factory.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: factory.region_name,
              id: factory.region,
              options: [
                {name: factory.name, id: factory.id, sid: factory.sid}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: factory.name, id: factory.id, sid: factory.sid});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    }
  },
  mounted() {
    this.getFactoryList();
  },
  methods: {
    getFactoryList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 100,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.factory_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
    },
    onCreate() {
      window.open(
        `https://quickchart.io/chart?cht=qr&chs=500x500&chl=${location.protocol}//${location.host}/%23/service/delivery/wearing/${this.factory}`,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
